import React, { useState } from "react";
import "./levelupdate.scss";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
export const LevelUpdate = () => {
  const [memberid, setMemberId] = useState("");
  const [updLevel, setUpLevel] = useState("");
  const [amount, setAmount] = useState("");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;

  const FetchData = async () => {
    const resp = await axios.post(
      url + "/admin/fetchlevel",
      { memberid, updLevel, amount },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
        alert("Fetched...")
    }
  };
  return (
    <div className="levelupdate">
      <div className="gri">
        <div className="col-12 md:col-12 lg:col-12">
          <InputText
            placeholder="Member Id"
            value={memberid}
            onChange={(e) => setMemberId(e.target.value)}
          />
          <InputText
            placeholder="Level"
            value={updLevel}
            onChange={(e) => setUpLevel(e.target.value)}
          />
          <InputText
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Button label="Fetch Details" onClick={() => FetchData()} />
        </div>
      </div>
    </div>
  );
};
