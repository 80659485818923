import React, { useRef, useState } from "react";
import "./adminlogin.scss"
import "./adminlogin.scss";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
export const AdminLogin = () => {
    const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const validationSchema = Yup.object({
    username: Yup.string("Invalid Username Id").required(
      "Username cant not be blank."
    ),
    password: Yup.string("Invalid Password")
      .required("Password cant not be blank.")
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
        const {username, password} = values
      setButtonDisabled(true);
      const resp = await axios.post(url + "/adminlogin",{
        username,
        password
      },{
        headers:{
            "x-api-key": apikey,
        }
      })
      if(resp.status===201){
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "User or Password not matched.",
          });
        setButtonDisabled(false)
      }else if(resp.status===200){
        Navigate("/admin/")
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className='adminlogin'>
        <Toast ref={toast} />
      <Card>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div>
                <div>USER NAME</div>
                <InputText
                  type="text"
                  name="username"
                  className="p-inputtext-sm"
                  placeholder="User Name"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
                {getFormErrorMessage("username")}
              </div>
              <div>
                <div>PASSWORD</div>
                <InputText
                  name="password"
                  type="password"
                  className="p-inputtext-sm"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {getFormErrorMessage("password")}
              </div>
              {isButtonDisabled === false ? (
              <Button type="submit" label="Submit" />
            ) : (
              <Button
                type="button"
                variant="outlined"
                icon="pi pi-spin pi-spinner"
                label="Submitting..."
              />
            )}
            </div>
            
          </Form>
        </FormikProvider>
      </Card>
    </div>
  )
}
