import React from 'react'
import "./admindashboard.scss"
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
export const AdminDashBoard = () => {
  return (
    <div className='admindashboard'>
        <div className="grid">
        <div className="col-12 md:col-12 lg:col-4">
            <Link to="/admin/levelcheck">
            <Button label='Level Update' />
            </Link>
        </div>
        </div>
    </div>
  )
}
