import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import "./mybusiness.scss";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
export const MyBusiness = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const actuser = localStorage.getItem("mgsactuser");
  const initialized = useRef(false);
  const [poolData, setPoolData] = useState({})
  const [total, setTotal] = useState(0)
  const [income, setIncome] = useState({
    WEEKLY: 0,
    LEVEL: 0,
    REWARD: 0,
  });
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/fetchbalance",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        console.log(resp.data)
        setPoolData(resp.data.pooldata)
        updateData(resp.data.data);
        setTotal(resp.data.sum)
        
      }
    };
    // if (!initialized.current) {
    //   initialized.current = true;
    fetchData();
    // }
  }, []);
  async function updateData(xdata) {
    for (let i = 0; i < xdata.length; i++) {
      if (xdata[i]._id === "WEEKLY") {
        setIncome((prevIncome) => ({ ...prevIncome, WEEKLY: xdata[i].sum }));
      } else if (xdata[i]._id === "LEVEL") {
        setIncome((prevIncome) => ({ ...prevIncome, LEVEL: xdata[i].sum }));
      } else if (xdata[i]._id === "REWARD") {
        setIncome((prevIncome) => ({ ...prevIncome, REWARD: xdata[i].sum }));
      }
    }
  }
  return (
    <div className="mybusiness">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <div className="cont">
            <div className="titel">DAILY INCOME</div>
            <div className="amt">
              <div className="amount">{income.WEEKLY.toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">LEVEL INCOME</div>
            <div className="amt">
              <div className="amount">{income.LEVEL.toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">REWARD INCOME</div>
            <div className="amt">
              <div className="amount">{income.REWARD.toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">TOTAL INCOME</div>
            <div className="amt">
              <div className="amount">
                {(income.WEEKLY + income.LEVEL + income.REWARD).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">
        <DataTable value={poolData}>
                <Column field="tran_date" header="Date"></Column>
                <Column field="pool_amt" header="Amount"></Column>
            </DataTable>
        </div>
      </div>
      <div className="grid mt-3">
        <div className="col-12 md:col-12 lg:col-3">
          <div className="align-items-center text-center bg-primary p-2">
            <div className="text-2xl">Total Invest</div>
            <div>{total}</div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <div className="align-items-center text-center bg-primary p-2">
            <div className="text-2xl">Total Income</div>
            <div>{(income.WEEKLY + income.LEVEL + income.REWARD).toFixed(2)}</div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <div className="align-items-center text-center bg-primary p-2">
            <div className="text-2xl">Remain</div>
            <div>{total*3 - (income.WEEKLY + income.LEVEL + income.REWARD).toFixed(2) }</div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-3">
          <div className="align-items-center text-center bg-primary p-2">
            <div className="text-2xl">Valid Upto</div>
            <div>{total*3}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
