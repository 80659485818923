import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import axios from "axios";
import "./mybusiness.scss";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const MyBusiness = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const actuser = localStorage.getItem("mgsactuser");
  const initialized = useRef(false);
  const [income, setIncome] = useState({
    WEEKLY:0,
    LEVEL:0,
    REWARD:0,
  });
  useEffect(() => {
    const fetchData = async () => {
        const resp = await axios.post(
            url + "/user/fetchbalance",
            {
              actuser,
            },
            {
              headers: {
                "x-api-key": apikey,
              },
            }
          );
          if (resp.status === 200) {
            await updateData(resp.data.data)
            console.log(resp.data.data);
          }
    };
    // if (!initialized.current) {
    //   initialized.current = true;
      fetchData();
    // }
  }, []);
  async function updateData(xdata){
    for(let i=0; i < xdata.length; i++){
      if(xdata[i]._id==='WEEKLY'){
        setIncome(prevIncome => ({...prevIncome, WEEKLY: xdata[i].sum}))
      }else if(xdata[i]._id==='LEVEL'){
        setIncome(prevIncome => ({...prevIncome, LEVEL: xdata[i].sum}))
      }else if(xdata[i]._id==='REWARD'){
        setIncome(prevIncome => ({...prevIncome, REWARD: xdata[i].sum}))
      }
    }
  }
  return (
    <div className="mybusiness">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <div className="cont">
            <div className="titel">DAILY INCOME</div>
            <div className="amt">
              <div className="amount">{(income.WEEKLY).toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">LEVEL INCOME</div>
            <div className="amt">
              <div className="amount">{(income.LEVEL).toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">REWARD INCOME</div>
            <div className="amt">
              <div className="amount">{(income.REWARD).toFixed(2)}</div>
            </div>
          </div>
          <div className="cont">
            <div className="titel">TOTAL INCOME</div>
            <div className="amt">
              <div className="amount">{(income.WEEKLY + income.LEVEL + income.REWARD).toFixed(2)}</div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">

        </div>
      </div>
    </div>
  );
};
