import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import abi from "../../../abi.json";
import "./withdraw.scss";
import moment from "moment";
import { ethers } from "ethers";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const Withdraw = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(-1);
  const [avlusdt, setUsdt] = useState(0);
  const [soarxprice, setSoarPrice] = useState(0);
  const actuser = localStorage.getItem("mgsactuser");
  const contract = "0x4df8a874b9A6C3E35EA9f0131D7355a932D596E8";
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/checkwithdraw",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setSoarPrice(resp.data.soarxprice);
        setUsdt(resp.data.balance);
        setData(resp.data.withlist);
        if (resp.data.balance * 1 > 10) {
          setButtonDisabled(0);
        }
        formik.setFieldValue("avlusdt", resp.data.balance);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    avlusdt: Yup.number("Invalid Value Found").required(
      "Field cant not be blank."
    ),
    withusdt: Yup.number("Invalid Value Found")
      .required("Field cant not be blank.")
      .min(10, "Value Must be greterthan or equal 10")
      .lessThan(Yup.ref("avlusdt")),
  });
  const formik = useFormik({
    initialValues: {
      avlusdt: 0,
      withusdt: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      let withusdt = values.withusdt;
      setButtonDisabled(1);
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        var mycontract = new ethers.Contract(contract, abi, signer);
        try {
          mycontract
            .contribute({ value: ethers.utils.parseUnits("1", "ether") })
            .then(function (done) {
              axios
                .post(
                  url + "/user/withdraw",
                  { wa, withusdt },
                  {
                    headers: {
                      "x-api-key": apikey,
                    },
                  }
                )
                .then(function (resp) {
                  setButtonDisabled(2);
                  formik.values.avlusdt = parseInt(0);
                  formik.values.withdraw = parseInt(0);
                  toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Your Withdraw is Successfull",
                  });
                });
            })
            .catch(function (error) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong!",
              });
              setButtonDisabled(1);
            });
        } catch (error) {}
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="withdraw">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="WITHDRAW">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="avail">Available Balance: {avlusdt}</div>
                  <div>
                    <div>AVAILABLE</div>
                    <InputText
                      type="number"
                      name="avlusdt"
                      className="p-inputtext-sm"
                      placeholder="Available"
                      onChange={formik.handleChange}
                      value={formik.values.avlusdt}
                    />
                    {getFormErrorMessage("stacksoarx")}
                  </div>
                  <div>
                    <div>WITHDRAW</div>
                    <InputText
                      type="number"
                      name="withusdt"
                      className="p-inputtext-sm"
                      placeholder="Withdraw"
                      onChange={formik.handleChange}
                      value={formik.values.withusdt}
                    />
                    {getFormErrorMessage("withusdt")}
                  </div>
                  {isButtonDisabled === -1 ? (
                    <Button type="button" disabled>
                      Not Possible
                    </Button>
                  ) : (
                    <>
                      {isButtonDisabled === 0 ? (
                        <Button type="submit">Submit</Button>
                      ) : (
                        <>
                          {isButtonDisabled === 1 ? (
                            <Button
                              type="button"
                              variant="outlined"
                              icon="pi pi-spin pi-spinner"
                            >
                              {" "}
                              Submitting..
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              variant="outlined"
                              icon="pi pi-check-circle"
                            >
                              {" "}
                              Submited
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">
          <Card title="Withdraw History">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row._id.toString()}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.usd_val}</TableCell>
                      <TableCell align="center">
                        {row.is_conf === 1 ? "Succcess" : "Error"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
