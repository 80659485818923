// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .admindashboard {
  padding: 120px 80px;
  background-color: rgb(238, 244, 247);
  color: #000;
  min-height: 100vh;
}
.theme-dark .admindashboard {
  padding: 120px 80px;
  background-color: #1e3948;
  color: whitesmoke;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/style.scss","webpack://./src/pages/admin/admindashboard.scss"],"names":[],"mappings":"AAwBI;ECrBI,mBAAA;EACA,oCAAA;EACA,WAAA;EACA,iBAAA;AADR;ADmBI;ECrBI,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,iBAAA;AAKR","sourcesContent":["$themes: (\n  light: (\n    navDefault: #ffffff5f,\n    bg:rgb(238, 244, 247),\n    footerBg:rgb(230, 243, 250),\n    cardLight:rgb(238, 244, 247),\n    textColor: #000,\n    textContra:#512727,\n    linkText:rgb(61, 61, 138),\n    menuBg:rgb(214, 214, 244)\n  ),\n  dark: (\n    navDefault: #1b35434f,\n    bg: #1e3948,\n    footerBg: #142b37,\n    cardLight:#1c3645,\n    textColor: whitesmoke,\n    textContra:#f5ff84,\n    linkText:aqua,\n    menuBg:#132d3a\n  ),\n);\n@mixin themify($themes) {\n  @each $theme, $map in $themes {\n    .theme-#{$theme} & {\n      $theme-map: () !global;\n      @each $key, $submap in $map {\n        $value: map-get(map-get($themes, $theme), \"#{$key}\");\n        $theme-map: map-merge(\n          $theme-map,\n          (\n            $key: $value,\n          )\n        ) !global;\n      }\n      @content;\n      $theme-map: null !global;\n    }\n  }\n}\n\n@function themed($key) {\n  @return map-get($theme-map, $key);\n}\n\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n@mixin tablet {\n  @media (max-width: 960px) {\n    @content;\n  }\n}","@import \"../../style.scss\";\n.admindashboard{\n    @include themify($themes){\n        padding: 120px 80px;\n        background-color: themed('bg');\n        color: themed('textColor');\n        min-height: 100vh;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
